<div id="canvas">
  <div class="flipbook-viewport">
    <div class="container">
      <div class="flipbook">
        <div class="book-cover"></div>
        <div class="inside-cover">
          <div class="total-ave"></div>
        </div>
        <div>
          <div class="book-index">
            <div class="index-heading">
              <h1>CONTENTS</h1>
            </div>
            <div class="GroupBy row"></div>
            <div class="index-content"></div>
          </div>
        </div>
        <!-- Next button -->
        <div ignore="1" class="next-button"></div>
        <!-- Previous button -->
        <div ignore="1" class="previous-button"></div>
      </div>
    </div>
    <div class="bottom"></div>
  </div>
</div>
